.imgFlying {
    position: absolute;
    /* width: 200px;
    height: 200px; */
    top: 0;
    left: 0;
    z-index: 5;
    /* animation: fly 0.8s 1;
    -webkit-animation: fly 0.8s 1; */
    -webkit-backface-visibility: hidden;
}

@keyframes fly {
    0% {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes fly {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

/* #btnShoppingCart > span{
    font-size: 1em;
} */