.number-input input[type="number"] {
    -webkit-appearance: textfield;
      -moz-appearance: textfield;
            appearance: textfield;
  }
  .number-input input[type=number]::-webkit-inner-spin-button,
  .number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .number-input {
    margin-bottom: 3rem;
  }
  .number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
  }
  .number-input button:before,
  .number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
  }
  .number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .number-input input[type=number] {
    text-align: center;
  }
  .number-input.number-input {
    border: 1px solid #ced4da;
    width: 100%;
    border-radius: 10em;
  }
  .number-input.number-input button {
    width: 32%;
    height: .7rem;
  }
  /* .number-input.number-input button.minus {
    padding-left: 10px;
  } */
  .number-input.number-input button:before,
  .number-input.number-input button:after {
    width: .7rem;
    background-color: #495057;
  }
  .number-input.number-input input[type=number] {
    max-width: 33%;
    padding: .5rem;
    border: 1px solid #ced4da;
    border-width: 0 1px;
    font-size: 1rem;
    height: 2rem;
    /* color: #495057; */
  }

  .placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa !important;
    font-size: 0.9em;
    opacity: 1; /* Firefox */
  }
  
  .placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #aaa !important;
  }
  
  .placeholder::-ms-input-placeholder { /* Microsoft Edge */
    color: #aaa !important;
  }