body {
    background: #f0f0f0;
}

#divHomeSearch div[data-section],
#divHomeSearch div[data-section] .spnItem {
    transition: ease-in-out all 0.15s;
}

#divHomeSearch div[data-section].active {
    color: #f0b536 !important;
}

#divHomeSearch div[data-section].active .spnItem {
    border: 2px solid #f0b536 !important;
}

#divHomeSearch .divBackground {
    background-color: rgba(0, 0, 0, .7);
    height: 200px;
}

@media only screen and (max-width: 768px) {
    #divHomeSearch .divBackground {
        height: 100px;
    }
}



#divHomeSearch .divBackground>div {
    margin-top: -50px;
}

#divHomeSearch .spnBG {
    width: 100px;
    height: 50px;
    background-color: rgba(0, 0, 0, .6);
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

#divHomeSearch .spnItem {
    display: inline-block;
    width: 80px;
    text-align: center;
    margin: 10px;
    height: 80px;
    border-radius: 50% !important;
    border: 2px solid #fff;
    line-height: 95px;
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

#divHomeSearch .spnItem i {
    font-size: 2em;
}

#divHomeSearch .col p {
    margin-top: 100px;
}

#divHomeSearch div[data-section] {
    cursor: pointer;
    display: flex;
    max-width: 100px;
    margin: auto;
    text-align: center;
    color: #fff;
}

#divHomeSearch div[data-section]:hover {
    color: #f0b536;
}

#divHomeSearch div[data-section]:hover .spnItem {
    border-color: #f0b536;
}
#divCapsule {
    z-index: 5;
}
@media only screen and (max-width: 767px){
    #divCapsule {
       
        top: 71px !important;
    }
}
@media only screen and (max-width: 738px) {
    #divHomeSearch .divBackground>div {
        margin-top: -25px;
    }

    #divHomeSearch .spnBG {
        width: 50px;
        height: 25px;
    }

    #divHomeSearch .spnItem {
        width: 40px;
        margin-top: 5px;
        height: 40px;
        line-height: 45px;
    }

    #divHomeSearch .col p {
        margin-top: 50px;
    }

    #divHomeSearch .spnItem i {
        font-size: 1em;
    }



    .foodcartnumber {
        margin-top: -5px;
    }

    .closeLightbox {
        display: block;
    }

    .ril-close {
        display: none;
    }
}

@media only screen and (min-width: 739px) {
    .closeLightbox {
        display: none;
    }

    .ril-close {
        display: inline-block;
    }
}

#divHomeSearch .select-wrapper input.select-dropdown {
    padding-right: 2px;
    border: none;
}

#divHomeSearch .select-wrapper span.caret {
    left: 5px;
}

.dropdown-content.select-dropdown.active {
    margin-left: 2px;
}

.form-check-input[type="radio"]:not(:checked)+label:before {
    border: 2px solid #ffffff;
}

.form-check-input[type="radio"]:checked+label:after {
    background-color: #f0b536;
    border: 2px solid #f0b536;
}

ul.react-multi-carousel-track {
    margin-bottom: 10px;
}

.customNavbar {
    box-shadow: none !important;
}

#rating .far,
#rating .fa {
    font-size: 30px;
    color: #ffd700;
}

#rating {
    font-size: 0;
    width: 100%;
}

nav aside ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-align: left;
}

#divHomeMenu {
    font-size: 0.65em;
    box-shadow: none !important;
}

@media screen and (min-width: 600px) {
    #divHomeMenu {
        font-size: 0.9em;
    }


}
@media (min-width:768px) and (max-width:991px){
    #divCapsule {
        right: 9%;
        top: 85px !important;
    }
}
@media (min-width:992px) and (max-width:1199px){
    #divCapsule {
        right: 10%;
        top: 88px !important;
    }
}
@media (min-width:1200px) and (max-width:1474px){
    #divCapsule {
        right: 11%;
        top: 102px !important;
    }
}
@media (min-width:1475px) and (max-width:1800px){
    #divCapsule {
        right: 19%;
        top: 102px !important;
    }
}
@media screen and (min-width: 1801px){
    #divCapsule {
        right: 21%;
        top: 102px !important;
    }
}

/* .fixedHomeNav{
      position :fixed;
      top:0;
      left: 0;
      z-index: 9999;
      width: 40% !important;
      background-color: transparent !important;
  } */

#HomeMenuNav.noHomeMenu {
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.paymentMethod label {
    padding-left: 23px !important;
    font-size: 0.9em !important;
}

#veganImg {
    width: 20px;
    bottom: 29px;
    right: 65px
}

@media only screen and (max-width: 738px) {
    #veganImg {
        bottom: 38px !important;
        right: 0px !important;
    }
}

.spnCount {

    padding: 5px 0
}

.spnMinus {

    width: 35px !important;
    height: 35px !important;

}

.spnCount-home {

    padding: 5px 0
}

.spnMinus-home {

    width: 40px !important;
    height: 40px !important;

}

@keyframes spnCountKeyFrames {
    from {
        width: 0;
        opacity: 0;
    }

    to {
        width: 50px;
        opacity: 1;
    }
}

.spnCountAnimate {
    animation-name: spnCountKeyFrames;
    animation-duration: 0.5s;
    transition: width 0.5s;
    width: 50px;
}

@keyframes spnMinusKeyFrames {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.spnMinusAnimate {
    animation-name: spnMinusKeyFrames;
    animation-duration: 0.5s;
    transition: all 0.5s;
}

@keyframes spnCountBackKeyFrames {
    from {
        width: 82;
        opacity: 1;
    }

    to {
        width: 0px;
        opacity: 0;
    }
}

.spnCountBack {
    animation-name: spnCountBackKeyFrames;
    animation-duration: 0.5s;
    transition: width 0.5s;
    width: 0px;
}

@keyframes spnMinusBackKeyFrames {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.spnMinusBackHome {
    animation-name: spnMinusBackKeyFrames;
    animation-duration: 0.5s;
    transition: all 0.5s;
}

.langFlag {
    height: 30px;
    width: 30px;
}

.form-control.reqForm:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 03, 0, 0.25);
}

#orderModal {
    top: 50px !important
}

.rounded-zolfa {
    border-radius: 15px !important;
}

.rounded-zolfa-top-right {
    border-top-right-radius: 15px !important;
}

.rounded-zolfa-top-left {
    border-top-left-radius: 15px !important;
}

.rounded-zolfa-bottom-right {
    border-bottom-right-radius: 15px !important;
}

.rounded-zolfa-bottom-left {
    border-bottom-left-radius: 15px !important;
}

#orderModal .modal-content {
    border-radius: 15px !important;
}



#orderModal .modal-title span {
    font-size: 16px !important;
    color: #000;
}

#confirmModal .modal-content {
    border-radius: 15px !important;
}

#confirmModal .form-cascading {
    margin-top: 12rem !important;
}

#closedModal .modal-content {
    border-radius: 15px !important;
}

#closedModal .form-cascading {
    margin-top: 12rem !important;
}

.cartFix {
    bottom: 70px;
    right: 10px;
    margin-left: -70px;
    /* border: 1px solid #019d40; */
    position: fixed !important;
    z-index: 99;
    width: 140px;
    background-color: #7ee99c;
    color: rgb(14, 14, 14);
    box-shadow: 4px 8px 5px 1px rgb(0 0 0 / 38%);
    border-radius: 20px;
    display: flex;
}
.requestFix {
    bottom: 70px;
    left: 10px;
    /* border: 1px solid #019d40; */
    position: fixed !important;
    z-index: 99;
    width: 140px;
    background-color: #c0c0c0;
    color: rgb(14, 14, 14);
    box-shadow: 4px 8px 5px 1px rgb(0 0 0 / 38%);
    border-radius: 20px;
    display: flex;
    cursor: pointer;
}
.cartFixCount {
    top: -13px;
    right: -8px;
    width: 25px !important;
    border-radius: 50%;
    height: 25px !important;
    font-size: 0.9em;
    padding: 3px 0px 0px 0px;
}

.orderHistory {
    bottom: 15px;
    position: fixed !important;
    background-color: rgb(0 0 0 / 50%);
    color: white;
    right: 0;
    width: 100%;
    text-align: right;
    z-index: 99;
}

.orderHistory p {
    margin: 0px;
}

.details-row {
    flex-direction: row;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    min-width: 0;
    justify-content: space-between;
    line-height: 24px;
    position: relative;
}

#orderModal .modal-body {
    max-height: 70vh !important;
    overflow-y: scroll;
}

.modal {
    z-index: 9999 !important;
}

.py-25 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.col-35 {
    flex: 0 0 30%;
    max-width: 30%;
}

.col-85 {
    flex: 0 0 69.0%;
    max-width: 69.0%;
}

#divOrder {
    scroll-margin: 250px;
}

.closeLightbox {
    position: absolute;
    top: 120px;
    right: 0;
    z-index: 9999;
    bottom: 0;
    color: #fff;
    left: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.mr-z {
    margin-right: .15rem !important;
}

.bg-shoppingcart {
    background-color: #c7c7c7 !important;
}

.btn-green-c {
    color: #000 !important;
    background-color: #7ee99c !important;
}

.btn-danger-c {
    color: #000 !important;
    background-color: #ebbab8 !important;
}

.text-black {
    color: #000 !important;
}

@media (min-width: 768px) {
    #cartModal .modal-dialog {
        top: 130px;
        position: absolute !important;
        width: 520px;
        right: 20% !important;
    }

    .cartFix {
        right: 18%;
    }
    .requestFix{
        left: 18%;
    }
}

#cartModal .modal-dialog .modal-content {
    border-radius: 15px;
}

#cartModal .modal-title {
    width: 100% !important;
}
@media (min-width: 1921px) {
    #cartModal .modal-dialog {
        top: 130px;
        position: absolute !important;
        width: 520px;
        right: 25% !important;
    }
    .cartFix {
        right: 25%;
    }
    .requestFix{
        left: 25%;
    }
}
@media (min-width: 2560px) {
    #cartModal .modal-dialog {
        top: 130px;
        position: absolute !important;
        width: 520px;
        right: 30% !important;
    }
    .cartFix {
        right: 30%;
    }
    .requestFix{
        left: 30%;
    }
}
.bg-dark-c{
    background-color: #4c4c4c !important;
}
.darkmode .text-black-50{
    color: #e0e0e0 !important;
}
.darkmode .text-black {
    color: #ffffff !important;
}
.darkmode .bg-white{
    background-color: #212121 !important;
}
.darkmode{
    border-color: #ffffff !important;
}
.darkmode .bg-light{
    background-color: #4c4c4c !important;
}
.darkmode .card{
    background-color: #4c4c4c !important;
}
.darkmode ::placeholder {
    color: rgb(255, 255, 255) !important;
  }
  .darkmode .form-control{
    color: #f0f0f0 !important;
  }
  .darkmode .spnMinus .text-black-50{
    color: #212121 !important;
  }
  .darkmode .spnMinus-home .text-black-50{
    color: #212121 !important;
  }
  .darkmode .spnPlus .text-black-50{
    color: #212121 !important;
  }
  .darkmode nav aside ul li{
    background-color: #212121 !important ;
    color: #ffffff;
  }
  .darkmode .modal-dialog.modal-notify .close span{
    color: #ffffff !important;
  }

  .foodImage{
    height: 190px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0px 0px;
  }

  @media (min-width: 1024px){
    .foodImage{
        height: 250px;
      }
  }

  .lineContainer {
    margin: 2em 0;
}
.lineContainer .line {
    display: inline-block;
    background: #cfcfcf;
    height: 1px;

    vertical-align: middle;
}
.lineContainer .text {
    vertical-align: middle;
    display: inline-block;
    padding: 0 .5em;
    font-size: 21px;
}

.darkmode .lineContainer .line{
    background: #fff !important;
}
.modal-dialog.modal-notify .close{
    position: absolute;
    top: 5px;
    right: 13px;
    text-shadow: none !important;
}
.modal-dialog.modal-notify .close span{
    width: 36px;
    height: 36px;
    text-align: center;
    right: 7px;
    background-color: #ababab;
    position: absolute;
    border-radius: 50%;
    line-height: 1.7;
}

.darkmode .modal-dialog.modal-notify .close span{
    background-color: #212121;
}
.modal-header{
    min-height: 46px;
}

.form-control:focus{
    border-color: #ffbc7e !important;
    box-shadow: 0 0 0 0.2rem rgb(255 142 37 / 25%) !important;
}

.searchClear{
    z-index: 99;
    top: 9px;
    right: 46px;
    font-size: 20px;
}

.btnRequest{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid #ffbc7e;
    text-align: center;
    width: 105px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 95px;;
}
@media (min-width: 1024px){
    .btnRequest{
        width: 110px;
      }
  }
#requestModal .modal-dialog .modal-content {
    border-radius: 15px;
}
#LoginRegisterModal .modal-dialog .modal-content {
    border-radius: 15px;
}

#LoginRegisterModal .modal-title {
    width: 100% !important;
}
#LoginRegisterDiv input::-webkit-outer-spin-button,
#LoginRegisterDiv input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

#LoginRegisterDiv input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.otpInput{
    width: 50px;
    border: 1px solid #525252 !important;
    text-align: center;
    border-radius: 5px;
}
.otpInput:focus-visible,.otpInput:focus{
    border: 1px solid #f57c00 !important;
}

.divAddressItem{
    border-radius: 5px;

}
.divAddressItemSelected{
    border: 1px solid #80bdff;
}
#ChangeAddressModal .modal-dialog .modal-content {
    border-radius: 15px;
}
#AddAddressModal .modal-dialog .modal-content {
    border-radius: 15px;
}
.border-left-888{
    border-left: 1px solid #575858 !important;
}
#HomeMenuNav ul li{
    border-bottom: 1px solid #e4e4e4;
}
.divSocialFooter{
    top: -26px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    position:absolute;
}

.accordion-888 {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .accordion-888.active, .accordion-888:hover {
    background-color: #ccc;
  }
  
  .accordion-888:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }
  
  .accordion-888.active:after {
    content: "\2212";
  }
  
  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  li:has(> span.border-0) {
        border: none !important;
  }

