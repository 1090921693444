/* Provide sufficient contrast against white background */
@font-face {
    font-family: "YekanNumbers";
    src: url(/fonts/IS/YekanNumbers-Regular.eot);
    src: url(/fonts/IS/YekanNumbers-Regular.eot?#iefix) format("embedded-opentype"),url(/fonts/IS/YekanNumbers-Regular.woff) format("woff"),url(/fonts/IS/YekanNumbers-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: "harabara";
    src: url(/fonts/IS/harabara-webfont.woff2) format("woff"),url(/fonts/IS/harabara-webfont.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: "eqpro";
    src: url(/fonts/IS/eqpro.woff2) format("woff"),url(/fonts/IS/eqpro.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: IS;
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/IS/IRANSansWeb_Bold.eot);
    src: url(/fonts/IS/IRANSansWeb_Bold.eot?#iefix) format("embedded-opentype"),url(/fonts/IS/IRANSansWeb_Bold.woff2) format("woff2"),url(/fonts/IS/IRANSansWeb_Bold.woff) format("woff"),url(/fonts/IS/IRANSansWeb_Bold.ttf) format("truetype")
}

@font-face {
    font-family: IS;
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/IS/IRANSansWeb_Medium.eot);
    src: url(/fonts/IS/IRANSansWeb_Medium.eot?#iefix) format("embedded-opentype"),url(/fonts/IS/IRANSansWeb_Medium.woff2) format("woff2"),url(/fonts/IS/IRANSansWeb_Medium.woff) format("woff"),url(/fonts/IS/IRANSansWeb_Medium.ttf) format("truetype")
}

@font-face {
    font-family: IS;
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/IS/IRANSansWeb_Light.eot);
    src: url(/fonts/IS/IRANSansWeb_Light.eot?#iefix) format("embedded-opentype"),url(/fonts/IS/IRANSansWeb_Light.woff2) format("woff2"),url(/fonts/IS/IRANSansWeb_Light.woff) format("woff"),url(/fonts/IS/IRANSansWeb_Light.ttf) format("truetype")
}

@font-face {
    font-family: IS;
    font-style: normal;
    font-weight: 200;
    src: url(/fonts/IS/IRANSansWeb_UltraLight.eot);
    src: url(/fonts/IS/IRANSansWeb_UltraLight.eot?#iefix) format("embedded-opentype"),url(/fonts/IS/IRANSansWeb_UltraLight.woff2) format("woff2"),url(/fonts/IS/IRANSansWeb_UltraLight.woff) format("woff"),url(/fonts/IS/IRANSansWeb_UltraLight.ttf) format("truetype")
}

@font-face {
    font-family: IS;
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/IS/IRANSansWeb.eot);
    src: url(/fonts/IS/IRANSansWeb.eot?#iefix) format("embedded-opentype"),url(/fonts/IS/IRANSansWeb.woff2) format("woff2"),url(/fonts/IS/IRANSansWeb.woff) format("woff"),url(/fonts/IS/ttf/IRANSansWeb.ttf) format("truetype")
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

body {
    font-family: IS !important;
}
.harabara{
    font-family:harabara !important;
}
.eqpro{
    font-family: eqpro !important;
}
.carousel-multi-item .carousel-item {
    display: flex !important;
}

.rtl {
    text-align: right;
    direction: rtl;
}

.ltr {
    direction: ltr !important;
}
.streak {
    display: block;
    position: relative;
    overflow: hidden;
    height: 250px;
}

    .streak.streak-md {
        height: 400px;
    }

@media (max-width: 736px) {
    .streak.streak-md {
        height: 300px;
    }
}

.streak.streak-lg {
    height: 650px;
}

@media (max-width: 450px) {
    .streak.streak-lg {
        height: 900px;
    }
}

.streak.streak-long {
    height: 200px;
}

@media (max-width: 450px) {
    .streak.streak-long {
        height: 620px;
    }
}

.streak.streak-long-2 {
    height: 400px;
}

@media (max-width: 450px) {
    .streak.streak-long-2 {
        height: 700px;
    }
}

.streak.streak-photo {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

@media (min-width: 1366px) {
    .streak.streak-photo {
        background-attachment: fixed;
    }
}

.streak.no-flex {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    height: auto;
}
.btn-floating {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    width: 47px;
    height: 47px;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin: 10px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
}

    .btn-floating i {
        font-size: 1.25rem;
        line-height: 47px;
    }

    .btn-floating i {
        display: inline-block;
        width: inherit;
        text-align: center;
        color: #fff;
    }

    .btn-floating:hover {
        -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .btn-floating:before {
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    .btn-floating.btn-sm {
        width: 36.15385px;
        height: 36.15385px;
    }

        .btn-floating.btn-sm i {
            font-size: 0.96154rem;
            line-height: 36.15385px;
        }

    .btn-floating.btn-lg {
        width: 61.1px;
        height: 61.1px;
    }

        .btn-floating.btn-lg i {
            font-size: 1.625rem;
            line-height: 61.1px;
        }

        
.md-tabs {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    border: 0;
    padding: 0.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: -20px;
    background-color: #2bbbad;
    z-index: 1;
    position: relative;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
}

    .md-tabs .nav-item + .nav-item {
        margin-left: 0;
    }

    .md-tabs .nav-item.disabled {
        pointer-events: none !important;
    }

        .md-tabs .nav-item.disabled .nav-link {
            color: #6c757d;
        }

    .md-tabs .nav-link {
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        border: 0;
        color: #fff;
    }

        .md-tabs .nav-link.active,
        .md-tabs .nav-item.open .nav-link {
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
            -webkit-border-radius: 0.25rem;
            border-radius: 0.25rem;
        }

    .md-tabs .nav-item.show .nav-link {
        background-color: #2bbbad;
        color: #fff;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
        -webkit-border-radius: 0.25rem;
        border-radius: 0.25rem;
    }

        .md-tabs .nav-item.show .nav-link.dropdown-toggle {
            background-color: rgba(0, 0, 0, 0.2);
        }

.tab-content {
    padding: 1rem;
    padding-top: 2rem;
}

    .tab-content.vertical {
        padding-top: 0;
    }

.md-pills {
    border: 0;
}

    .md-pills li {
        padding: 0.6rem;
    }

    .md-pills .show > .nav-link {
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        color: #fff;
        background-color: #2bbbad;
    }

    .md-pills .nav-link {
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        color: #666666;
        text-align: center;
    }

        .md-pills .nav-link.active {
            -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            color: #fff;
            background-color: #2bbbad;
        }

            .md-pills .nav-link.active:hover {
                -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
            }

.pills-primary .show > .nav-link {
    background-color: #4285f4 !important;
}

.pills-primary .nav-link.active {
    background-color: #4285f4 !important;
}

.tabs-primary {
    background-color: #4285f4 !important;
}

.pills-danger .show > .nav-link {
    background-color: #ff3547 !important;
}

.pills-danger .nav-link.active {
    background-color: #ff3547 !important;
}

.tabs-danger {
    background-color: #ff3547 !important;
}

.pills-warning .show > .nav-link {
    background-color: #ff8800 !important;
}

.pills-warning .nav-link.active {
    background-color: #ff8800 !important;
}

.tabs-warning {
    background-color: #ff8800 !important;
}

.pills-success .show > .nav-link {
    background-color: #00c851 !important;
}

.pills-success .nav-link.active {
    background-color: #00c851 !important;
}

.tabs-success {
    background-color: #00c851 !important;
}

.pills-info .show > .nav-link {
    background-color: #33b5e5 !important;
}

.pills-info .nav-link.active {
    background-color: #33b5e5 !important;
}

.tabs-info {
    background-color: #33b5e5 !important;
}

.pills-default .show > .nav-link {
    background-color: #2bbbad !important;
}

.pills-default .nav-link.active {
    background-color: #2bbbad !important;
}

.tabs-default {
    background-color: #2bbbad !important;
}

.pills-secondary .show > .nav-link {
    background-color: #aa66cc !important;
}

.pills-secondary .nav-link.active {
    background-color: #aa66cc !important;
}

.tabs-secondary {
    background-color: #aa66cc !important;
}

.pills-elegant .show > .nav-link {
    background-color: #2e2e2e !important;
}

.pills-elegant .nav-link.active {
    background-color: #2e2e2e !important;
}

.tabs-elegant {
    background-color: #2e2e2e !important;
}

.pills-unique .show > .nav-link {
    background-color: #880e4f !important;
}

.pills-unique .nav-link.active {
    background-color: #880e4f !important;
}

.tabs-unique {
    background-color: #880e4f !important;
}

.pills-dark-green .show > .nav-link {
    background-color: #388e3c !important;
}

.pills-dark-green .nav-link.active {
    background-color: #388e3c !important;
}

.tabs-dark-green {
    background-color: #388e3c !important;
}

.pills-mdb-color .show > .nav-link {
    background-color: #59698d !important;
}

.pills-mdb-color .nav-link.active {
    background-color: #59698d !important;
}

.tabs-mdb-color {
    background-color: #59698d !important;
}

.pills-red .show > .nav-link {
    background-color: #d32f2f !important;
}

.pills-red .nav-link.active {
    background-color: #d32f2f !important;
}

.tabs-red {
    background-color: #d32f2f !important;
}

.pills-pink .show > .nav-link {
    background-color: #ec407a !important;
}

.pills-pink .nav-link.active {
    background-color: #ec407a !important;
}

.tabs-pink {
    background-color: #ec407a !important;
}

.pills-purple .show > .nav-link {
    background-color: #8e24aa !important;
}

.pills-purple .nav-link.active {
    background-color: #8e24aa !important;
}

.tabs-purple {
    background-color: #8e24aa !important;
}

.pills-deep-purple .show > .nav-link {
    background-color: #512da8 !important;
}

.pills-deep-purple .nav-link.active {
    background-color: #512da8 !important;
}

.tabs-deep-purple {
    background-color: #512da8 !important;
}

.pills-indigo .show > .nav-link {
    background-color: #3f51b5 !important;
}

.pills-indigo .nav-link.active {
    background-color: #3f51b5 !important;
}

.tabs-indigo {
    background-color: #3f51b5 !important;
}

.pills-blue .show > .nav-link {
    background-color: #1976d2 !important;
}

.pills-blue .nav-link.active {
    background-color: #1976d2 !important;
}

.tabs-blue {
    background-color: #1976d2 !important;
}

.pills-light-blue .show > .nav-link {
    background-color: #82b1ff !important;
}

.pills-light-blue .nav-link.active {
    background-color: #82b1ff !important;
}

.tabs-light-blue {
    background-color: #82b1ff !important;
}

.pills-cyan .show > .nav-link {
    background-color: #00bcd4 !important;
}

.pills-cyan .nav-link.active {
    background-color: #00bcd4 !important;
}

.tabs-cyan {
    background-color: #00bcd4 !important;
}

.pills-teal .show > .nav-link {
    background-color: #00796b !important;
}

.pills-teal .nav-link.active {
    background-color: #00796b !important;
}

.tabs-teal {
    background-color: #00796b !important;
}

.pills-green .show > .nav-link {
    background-color: #388e3c !important;
}

.pills-green .nav-link.active {
    background-color: #388e3c !important;
}

.tabs-green {
    background-color: #388e3c !important;
}

.pills-light-green .show > .nav-link {
    background-color: #8bc34a !important;
}

.pills-light-green .nav-link.active {
    background-color: #8bc34a !important;
}

.tabs-light-green {
    background-color: #8bc34a !important;
}

.pills-lime .show > .nav-link {
    background-color: #afb42b !important;
}

.pills-lime .nav-link.active {
    background-color: #afb42b !important;
}

.tabs-lime {
    background-color: #afb42b !important;
}

.pills-yellow .show > .nav-link {
    background-color: #fbc02d !important;
}

.pills-yellow .nav-link.active {
    background-color: #fbc02d !important;
}

.tabs-yellow {
    background-color: #fbc02d !important;
}

.pills-amber .show > .nav-link {
    background-color: #ffa000 !important;
}

.pills-amber .nav-link.active {
    background-color: #ffa000 !important;
}

.tabs-amber {
    background-color: #ffa000 !important;
}

.pills-orange .show > .nav-link {
    background-color: #f57c00 !important;
}

.pills-orange .nav-link.active {
    background-color: #f57c00 !important;
}

.tabs-orange {
    background-color: #f57c00 !important;
}

.pills-deep-orange .show > .nav-link {
    background-color: #ff7043 !important;
}

.pills-deep-orange .nav-link.active {
    background-color: #ff7043 !important;
}

.tabs-deep-orange {
    background-color: #ff7043 !important;
}

.pills-brown .show > .nav-link {
    background-color: #795548 !important;
}

.pills-brown .nav-link.active {
    background-color: #795548 !important;
}

.tabs-brown {
    background-color: #795548 !important;
}

.pills-grey .show > .nav-link {
    background-color: #616161 !important;
}

.pills-grey .nav-link.active {
    background-color: #616161 !important;
}

.tabs-grey {
    background-color: #616161 !important;
}

.pills-blue-grey .show > .nav-link {
    background-color: #78909c !important;
}

.pills-blue-grey .nav-link.active {
    background-color: #78909c !important;
}

.tabs-blue-grey {
    background-color: #78909c !important;
}

.pills-dark .show > .nav-link {
    background-color: #212121 !important;
}

.pills-dark .nav-link.active {
    background-color: #212121 !important;
}

.tabs-dark {
    background-color: #212121 !important;
}

.pills-light .show > .nav-link {
    background-color: #e0e0e0 !important;
}

.pills-light .nav-link.active {
    background-color: #e0e0e0 !important;
}

.tabs-light {
    background-color: #e0e0e0 !important;
}

.pills-white .show > .nav-link {
    background-color: #fff !important;
}

.pills-white .nav-link.active {
    background-color: #fff !important;
}

.tabs-white {
    background-color: #fff !important;
}

.pills-black .show > .nav-link {
    background-color: #000 !important;
}

.pills-black .nav-link.active {
    background-color: #000 !important;
}

.tabs-black {
    background-color: #000 !important;
}

.classic-tabs .nav {
    white-space: nowrap;
    overflow-x: auto;
    position: relative;
    -webkit-border-radius: 0.3rem 0.3rem 0 0;
    border-radius: 0.3rem 0.3rem 0 0;
}

@media (min-width: 62rem) {
    .classic-tabs .nav {
        overflow-x: hidden;
    }
}

.classic-tabs .nav li a {
    display: block;
    padding: 20px 24px;
    font-size: 13px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-bottom: 3px transparent solid;
}

    .classic-tabs .nav li a.active {
        border-bottom: 3px solid;
        color: #fff;
    }


.classic-tabs .nav.tabs-cyan li a.active {
    border-color: #ffeb3b;
}

.classic-tabs .nav.tabs-orange li a.active {
    border-color: #e53935;
}

.classic-tabs .nav.tabs-grey li a.active {
    border-color: #fff;
}

.classic-tabs .nav.tabs-pink li a.active {
    border-color: #673ab7;
}

.classic-tabs .nav.tabs-green li a.active {
    border-color: #1565c0;
}

.classic-tabs .nav.tabs-primary li a.active {
    border-color: #fff;
}

.classic-tabs .tab-content.card {
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
}
.testimonial-card .card-up {
    overflow: hidden;
    height: 120px;
    -webkit-border-top-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-border-top-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.testimonial-card .avatar {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 120px;
    margin-top: -60px;
    overflow: hidden;
    border: 5px solid #fff;
}

    .testimonial-card .avatar img {
        width: 100%;
    }

.testimonial-card .card-body {
    text-align: center;
}
.c-pointer{
    cursor: pointer !important;
}

.btn-rounded {
    border-radius: 10em;
}
.DatePicker{
    width :100%;
}

.mt-6{
    margin-top: 5rem!important;
}
.mt-7{
    margin-top: 6rem!important;
}
.pagination li{
    padding: 5px;
}
.pagination li.active{
   color:#33b5e5 !important;
}
table.table td, table.table th {
    font-weight: normal !important;
}
.mh-25{
    min-height: 25vh !important;
}

.mh-50{
    min-height: 50vh !important;
}

.mh-75{
    min-height: 75vh !important;
}

.mh-100{
    min-height: 100vh !important;
}

.mh-125{
    min-height: 125vh !important;
}
@media (max-width: 992px){
    .navbar {
        position: fixed !important;
    }
}
.tl-font{
    font-family: sans-serif !important;
}
.ReactModal__Overlay--after-open{
    z-index: 999999999 !important;
}
.errorfocus{
    box-shadow: 0 0 0 0.2rem rgb(255 49 49 / 57%) !important;
}
p{
    margin: 0 !important;
}
.checkbox-circle {
    font-size: 20px;
    border: 10px solid #e9e9e9;
    border-radius: 30px;
}