.avatar {
    max-width: 120px;
    max-height: 120px;
    margin: -60px auto 0;
    border-radius: 50%;
    overflow: hidden;
  }
  
  #ulProfileMenu.striped li {
    padding: .5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
  }
  

  .listGroupItem.active button.btn-floating {
    box-shadow: none !important;
  }